<template>
  <div class="history__page">
    <Redirect title="Payout" route="website.settings.payout" />
    <div class="d-flex align-center justify-space-between my-4">
      <h2>Deposits History</h2>
      <!-- <v-btn outlined><v-icon>mdi-file-export mr-2</v-icon> Export </v-btn> -->
    </div>
    <v-form>
      <v-row>
        <v-col cols="5" md="auto" class="form-component  pb-0">
          <label>Start day</label>
          <v-menu
            ref="startDateMenuRef"
            v-model="dateMenu.start"
            :return-value.sync="table.filter.startDate"
            transition="scale-transition"
            day-format="YYYY-MM-DD"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="table.filter.startDate"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="getData()"
              ></v-text-field>
            </template>
            <v-date-picker v-model="table.filter.startDate" :max="table.filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateMenu.start = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.startDateMenuRef.save(table.filter.startDate);
                  getData();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5" md="auto" class="form-component pb-0">
          <label>End day</label>
          <v-menu
            ref="endDateMenuRef"
            v-model="dateMenu.end"
            :return-value.sync="table.filter.endDate"
            transition="scale-transition"
            day-format="YYYY-MM-DD"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="table.filter.endDate"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="getData()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="table.filter.endDate"
              :min="table.filter.startDate"
              :max="new Date().toISOString()"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateMenu.end = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.endDateMenuRef.save(table.filter.endDate);
                  getData();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="1" :sm="2" class="pb-0">
          <v-btn outlined class="mt-6" @click="getData()">Filter</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12" md="4" lg="6" xl="7" class="form-component">
        <label>Transaction ID</label>
        <v-text-field
          placeholder="Transaction ID"
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="getData()"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="4" lg="3" xl="3" class="form-component">
        <label>Paywith</label>
        <v-select
          placeholder="Paywith"
          v-model="table.filter.payWith"
          :items="payWithList"
          @change="getData()"
        ></v-select
      ></v-col>
      <v-col cols="6" md="4" lg="3" xl="2" class="form-component">
        <label>Status</label>
        <v-select placeholder="Status" v-model="table.filter.status" :items="statusList" @change="getData()"></v-select
      ></v-col>
    </v-row>

    <v-data-table
      :loading="table.loading"
      :headers="table.columns"
      :items="table.records"
      :items-per-page="table.pagination.limit"
      :page.sync="table.pagination.page"
      item-key="_id"
      no-data-text="There are no history"
      hide-default-footer
    >
      <template v-slot:[`item.type`]="{ item: { isCashback } }">
        <v-chip label small>{{ `${isCashback ? 'Cashback' : 'Topup'}` }}</v-chip>
      </template>
      <template v-slot:[`item.createdAt`]="{ item: { createdAt } }">
        {{ formatDate(createdAt) }}
      </template>
      <template v-slot:[`item.amount`]="{ item: { amount } }">{{ formatMoney(amount) }}</template>
      <template v-slot:[`item.balance`]="{ item: { balance } }">{{ formatMoney(balance) }}</template>

      <template v-slot:[`item.status`]="{ item: { status } }">
        <v-chip :color="colorStatus(status)" class="text-capitalize" label small>{{
          status === 'canceled' ? 'Rejected' : status
        }}</v-chip>
      </template>
    </v-data-table>
    <div class="d-flex justify-end mt-4" v-if="table.records.length">
      <v-pagination
        :length="table.pagination.total"
        v-model="table.pagination.page"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        hide
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Redirect from '@/components/RedirectTo';
import moment from 'moment';
import TopUpService from '@/apis/topUp';

export default {
  components: { Redirect },
  data() {
    return {
      table: {
        loading: false,
        columns: [
          { text: 'Created', value: 'createdAt', sortable: false, width: 150 },
          {
            text: 'Pay with',
            value: 'payWith',
            sortable: false,
            width: 120,
          },
          {
            text: 'Transaction ID',
            value: 'transactionId',
            sortable: false,
          },
          {
            text: 'Type',
            value: 'type',
            sortable: false,
          },
          {
            text: 'Amount',
            value: 'amount',
            sortable: false,
          },
          {
            text: 'Balance',
            value: 'balance',
            sortable: false,
          },
          {
            text: 'Status',
            value: 'status',
            sortable: false,
          },
          {
            text: 'Note',
            value: 'note',
            sortable: false,
          },
        ],
        records: [],
        pagination: {
          limit: 10,
          total: 1,
          page: 1,
        },
        filter: {
          startDate: null,
          endDate: moment().format('YYYY-MM-DD'),
          payWith: 'All',
          transactionId: null,
          status: 'all',
          note: null,
        },
      },
      payWithList: ['All', 'Pingpong', 'Payoneer', 'Bank transfer'],
      statusList: [
        { text: 'All', value: 'all' },
        { text: 'Pending', value: 'pending' },
        { text: 'Approved', value: 'approved' },
        { text: 'Rejected', value: 'canceled' },
      ],
      dateMenu: {
        start: false,
        end: false,
      },
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm');
    },
    formatMoney(money) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      let result = formatter.format(money);
      result = result.toString().slice(0, -3);
      return result;
    },
    colorStatus(status) {
      switch (status) {
        case 'approved':
          return 'success';
        case 'canceled':
          return 'error';
        case 'pending':
          return 'warning';
      }
    },
    getTotalPage(total, limit) {
      return total < limit ? 1 : parseInt(total / limit);
    },
    async getData() {
      try {
        this.table.loading = true;
        const query = {
          limit: this.table.pagination.limit,
          page: this.table.pagination.page,
          payWith: this.table.filter.payWith === 'All' ? null : this.table.filter.payWith,
          transactionId: this.table.filter.transactionId,
          status: this.table.filter.status === 'all' ? null : this.table.filter.status,
          note: this.table.filter.note,
          startDate: this.table.filter.startDate ? moment(this.table.filter.startDate).format('YYYY-MM-DD') : null,
          endDate: this.table.filter.startDate
            ? moment(this.table.filter.endDate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
        };
        const { data } = await TopUpService.getDepositHistory(query);
        this.table.records = data.data;
        this.table.pagination = {
          ...data.pagination,
          total: this.getTotalPage(data.pagination.total, data.pagination.limit),
        };
      } catch (error) {
        console.log('🚀 ~ file: index.vue:117 ~ getData ~ error:', error);
      } finally {
        this.table.loading = false;
      }
    },
  },
  created() {
    this.getData();
  },
  watch: {
    'table.pagination.page': function() {
      this.getData();
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 32px;
}
.v-btn--outlined {
  border: solid 1px lightgray;
  border-radius: 8px;
}
tbody tr:nth-of-type(even) {
  background-color: #fafafa;
}

.theme--light.v-data-table thead tr th {
  color: black !important;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}
tbody tr.v-data-table__empty-wrapper td {
  font-size: 20px !important;
  font-weight: 600;
  padding-block: 32px !important;
}
.v-input__prepend-inner {
  padding-right: 0px !important;
  margin-left: 8px;
}
</style>
